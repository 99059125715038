import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '@components/seo'
import Layout from '@components/layout'
import Footer from '@components/footers/dark'
import MenuBar from "@components/menuBar/MenuBar";
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import readingTime from 'reading-time'
import ShareButtons from '../components/share-buttons'

class BlogPostTemplate extends React.Component {
  render() {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const post = get(this.props, 'data.contentfulBlogPost')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const heroImage = getImage(post.heroImage)
    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))
    const { text: timeToRead } = readingTime(plainTextBody)

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { gatsbyImageData, description } = node.data.target
          return (
            <GatsbyImage
              image={getImage(gatsbyImageData)}
              alt={description}
            />
          )
        },
      },
    };

    return (
      <>
        <SEO title="Blog" />
        <MenuBar />
        <div className="pb-8">
          <GatsbyImage className="relative grid grid-cols-8 md:grid-cols-12 z-0 p-1 t-4 lg:pt-20 text-white lg:mb-0 h-[60vh]" image={heroImage} alt={post.heroImage.description} />
        </div>
        <Layout>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-left sm:text-center leading-8 font-extrabold tracking-tight text-primary sm:text-4xl">
                  <span className="block sm:inline">
                    {post.title}
                  </span>
                </span>
                <span className="block text-base text-left sm:text-center text-highlight font-semibold tracking-wide uppercase mt-4">
                  by {post.author.name}
                </span>
                <span className="block text-sm text-gray-400 sm:text-center mt-3">
                  {post.publishDate} - {timeToRead}
                </span>
                <span className="block text-base text-center text-highlight font-semibold tracking-wide mt-4">
                  <ShareButtons url={url} title={post.title} description={post.description} />
                </span>
              </h1>
            </div>
            <div className="mt-6 prose prose-lg text-gray-500 mx-auto">
              {post.body?.raw && renderRichText(post.body, options)}
            </div>
          </div>

          <div className="relative px-4 sm:px-6 lg:px-8 mt-6 mb-10">
            <div className="text-lg max-w-prose mx-auto flex justify-between">
              {(previous || next) && (
                <>
                  {previous && (
                    <Link to={`/blog/${previous.slug}`} rel="prev" className="text-lg font-medium text-highlight hover:text-highlight-dark">
                      <span aria-hidden="true">&larr;</span> {previous.title}
                    </Link>
                  )}
                  {next && (
                    <Link to={`/blog/${next.slug}`} rel="next" className="ml-auto text-lg font-medium text-highlight hover:text-highlight-dark">
                      {next.title} <span aria-hidden="true">&rarr;</span>
                    </Link>
                  )}
                </>
            )}
            </div>
          </div>
        </Layout>
        <Footer />
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  )
  {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      description
      author {
        name
      }
      publishDate(formatString: "Do MMMM, YYYY")
      rawDate: publishDate
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            gatsbyImageData
          }
        }
      }
      heroImage {
        description
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
